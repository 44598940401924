import css from "./Header.module.css";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import MobileMenu from "../MobileMenu/MobileMenu";
import logoImage from "../../public/assets/images/logo/fructus-x-logo.png";
import logoImage2x from "../../public/assets/images/logo/fructus-x-logo@2x.png";
import logoImage3x from "../../public/assets/images/logo/fructus-x-logo@3x.png";
import logoImage4x from "../../public/assets/images/logo/fructus-x-logo@4x.png";
import sprite from "../../public/assets/icons.svg";

const Header = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogoClick = () => {
    window.location.reload();
  };

  const handleHeaderButtonClick = () => {
    navigate("/contact");
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up the class on unmount
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isMobileMenuOpen]);

  return (
    <header
      className={clsx(css.header, {
        [css.headerFixed]: isMobileMenuOpen,
      })}
    >
      <div className={css.headerContainer}>
        <nav className={css.navLinkContainer}>
          <div className={css.logoLink} onClick={handleLogoClick}>
            <NavLink to="/">
              <img
                className={css.logoImage}
                src={logoImage}
                srcSet={`
                ${logoImage2x} 2x, 
                ${logoImage3x} 3x, 
                ${logoImage4x} 4x`}
                alt="Logo Image"
              />
            </NavLink>
          </div>
          <div className={css.navContainer}>
            <NavLink
              className={({ isActive }) =>
                clsx(css.headerLink, { [css.activeLink]: isActive })
              }
              to="/about"
            >
              About Us
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                clsx(css.headerLink, { [css.activeLink]: isActive })
              }
              to="/services"
            >
              Services
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                clsx(css.headerLink, { [css.activeLink]: isActive })
              }
              to="/projects"
            >
              Projects
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                clsx(css.headerLink, { [css.activeLink]: isActive })
              }
              to="/contact"
            >
              Contact Us
            </NavLink>
          </div>
          <button
            className={css.headerButton}
            onClick={handleHeaderButtonClick}
          >
            Join Us
          </button>
        </nav>
        <svg
          className={clsx(css.burgerMenuIcon, {
            [css.burger]: !isMobileMenuOpen,
            [css.close]: isMobileMenuOpen,
          })}
          onClick={toggleMobileMenu}
        >
          <use
            href={`${sprite}#${
              isMobileMenuOpen ? "icon-close" : "icon-burger-menu"
            }`}
          ></use>
        </svg>
        <MobileMenu isOpen={isMobileMenuOpen} onClose={toggleMobileMenu} />
      </div>
    </header>
  );
};

export default Header;
