import css from "./Footer.module.css";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import footerX from "../../public/assets/images/Xs/main-x.png";
import footerX2x from "../../public/assets/images/Xs/main-x@2x.png";
import footerX3x from "../../public/assets/images/Xs/main-x@3x.png";
import footerX4x from "../../public/assets/images/Xs/main-x@4x.png";
import sprite from "../../public/assets/icons.svg";

const Footer = () => {
  return (
    <footer className={css.footer}>
      <div className={css.footerContainer}>
        <div className={css.contactContainer}>
          <img
            className={css.footerLogo}
            src={footerX}
            srcSet={`
                ${footerX2x} 2x, 
                ${footerX3x} 3x, 
                ${footerX4x} 4x`}
            alt="Logo"
          />
          <div className={css.adressContainer}>
            <a href="mailto:info@fructus-x.com" className={css.emailLink}>
              info@fructus-x.com
            </a>
            <a
              href="https://www.google.com/maps/search/?api=1&query=115B+Zelena+Street,+Lviv,+Ukraine"
              target="_blank"
              rel="noopener noreferrer"
              className={css.addressLink}
            >
              115B Zelena Street, Lviv, Ukraine
            </a>
          </div>
          <div className={css.privacyPolicyContainer}>
            <a className={`${css.privacyItem} ${css.privacyLink}`} href="#">
              Terms and Conditions
            </a>
            <a className={css.privacyLink} href="#">
              Privacy Policy
            </a>
          </div>
        </div>
        <nav className={css.navLinkContainer}>
          <NavLink
            className={({ isActive }) =>
              clsx(css.footerLink, { [css.activeLink]: isActive })
            }
            to="/about"
          >
            About Us
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              clsx(css.footerLink, { [css.activeLink]: isActive })
            }
            to="/services"
          >
            Services
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              clsx(css.footerLink, { [css.activeLink]: isActive })
            }
            to="/projects"
          >
            Projects
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              clsx(css.footerLink, { [css.activeLink]: isActive })
            }
            to="/contact"
          >
            Contact Us
          </NavLink>
        </nav>
        <div className={css.footerIconsContainer}>
          <div className={css.socialMediaContainer}>
            <a className={css.socialMediaIconLink} href="#">
              <svg className={css.linkedInIcon}>
                <use href={`${sprite}#${"icon-linkedin"}`}></use>
              </svg>
            </a>
            <a className={css.socialMediaIconLink} href="#">
              <svg className={css.instagramIcon}>
                <use href={`${sprite}#${"icon-instagram"}`}></use>
              </svg>
            </a>
            <a className={css.socialMediaIconLink} href="#">
              <svg className={css.facebookIcon}>
                <use href={`${sprite}#${"icon-facebook"}`}></use>
              </svg>
            </a>
          </div>
          <div className={css.rightsContainer}>
            <div className={css.copyrightContainer}>
              <p className={css.privacyItem}>Copyright</p>
              <p className={css.privacyItem}>&copy;</p>
              <p className={css.privacyItem}>2024</p>
              <p className={css.privacyItem}>
                <span className={css.privacyAccent}>Fructus-X</span>
              </p>
            </div>
            <p className={css.privacyItem}>All Rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
